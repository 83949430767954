<template>
    <b-container fluid>
      <b-row>
        <b-col sm="12" md="6">
          <h2>
            <b-button class="mr-2" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'control' }}">Edit</b-button>
            {{item.name}} [{{item.id}}]
          </h2>
          <div class="mb-2" style="font-size: 1.2em; font-weight: 500">
            {{item.objective}}
          </div>
          <div v-html="item.description"></div>
        </b-col>
        <b-col sm="6" md="3">
          <h4>Status</h4>
          <div style="font-size: 1.2em">
            {{item.controlstatus.name}}
          </div>
          <h4 class="mt-5">Themes</h4>
          <div v-for="theme in item.controlthemes" :key="'theme' + theme.id" style="font-size: 1.2em">
            {{theme.name}}
          </div>
        </b-col>
        <b-col sm="6" md="3">
          <h4>Risks</h4>
          <div v-for="risk in item.controlrisks" :key="'risk' + risk.id" style="font-size: 1.2em">
            {{risk.name}}
          </div>
          <h4 class="mt-5">Functions</h4>
          <div v-for="area in item.areas" :key="'area' + area.id" style="font-size: 1.2em">
            {{area.name}}
          </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'

export default {
  name: 'Control',
  props: ['item', 'username'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permission.edit = ac.can(this.user.acgroups).updateAny('control').granted
  },
  data () {
    return {
      permission: {
        edit: false
      }
    }
  }
}
</script>

<style>
</style>
