<template>
  <div>
    <div class="mb-3">
      <b-form inline>
      <h2 v-if="!inputsReady">Select rows and columns:</h2>
      <b-form-select class="mx-2" v-model="columnSelected" :options="columnOptions"></b-form-select>
      X
      <b-form-select class="mx-2" v-model="rowSelected" :options="rowOptions"></b-form-select>
      reduce function:
      <b-form-select class="mx-2" v-model="reduce" :options="reduceOptions"></b-form-select>
      </b-form>
    </div>
    <table width="100%" class="table-matrix">
      <tr v-for="(row, rowIndex) in table" :key="'row-'+ row.id + Math.random()">
        <td v-for="(column, columnIndex) in row" :key="'column-' + column.id + Math.random()" style="border-color: #00b8f5;">
          <div v-if="!Array.isArray(column) && rowIndex===0" style="writing-mode: vertical-lr; text-orientation: mixed; margin-top: 10px; margin-bottom: 10px;">
            <strong>{{ column }}</strong>
          </div>
          <div v-if="!Array.isArray(column) && columnIndex===0" style="margin-left: 10px; margin-right: 10px;">
            <strong>{{ column }}</strong>
          </div>
          <div v-if="Array.isArray(column)">
            <div v-if="column.length > 0 && reduce==='none'">
              <div v-for="control in column" :key="'control-id-' + control.id">
                <router-link :to="{ name: routeName, params: { id: control.id }}">{{ control.name }} [{{control.id}}]</router-link>
              </div>
            </div>
            <div v-if="column.length === 0 && reduce==='none'"> </div>
            <div v-if="reduce==='count'">{{column.length}}</div>
            <div v-if="reduce==='binary' && column.length > 0" style="width: 100%; height: 100%; margin: auto; background-color: #00348d; color: #00348d;">.</div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'Control',
  props: ['rows', 'dictionaries', 'route'],
  computed: {
    inputsReady: function () {
      let result = true
      if (!this.columnSelected) {
        result = false
      }
      if (!this.rowSelected) {
        result = false
      }
      return result
    },
    table: function () {
      const table = []
      if (this.inputsReady) {
        const columnProperty = this.columnSelected
        const columnGraph = _.find(this.dictionaries, ['name', columnProperty]).graph
        const rowProperty = this.rowSelected
        const rowGraph = _.find(this.dictionaries, ['name', rowProperty]).graph
        const columns = _.find(this.dictionaries, ['name', columnProperty]).data
        const rows = _.find(this.dictionaries, ['name', rowProperty]).data
        const firstRow = _.concat(' ', columns)
        table.push(firstRow)
        let nextRow = []
        _.each(rows, rowName => {
          nextRow.push(rowName)
          _.each(columns, columnName => {
            let fish = _.filter(this.rows, x => {
              let result = false
              if (Array.isArray(x[columnGraph])) {
                result = _.find(x[columnGraph], ['name', columnName])
              } else {
                if (x[columnGraph].name === columnName) {
                  result = true
                }
              }
              return result
            })
            fish = _.filter(fish, x => {
              let result = false
              if (Array.isArray(x[rowGraph])) {
                result = _.find(x[rowGraph], ['name', rowName])
              } else {
                result = x[rowGraph].name === rowName
              }
              return result
            })
            nextRow.push(fish)
          })
          table.push(nextRow)
          nextRow = []
        })
      }
      return table
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    _.each(this.dictionaries, x => {
      this.columnOptions.push(x.name)
      this.rowOptions.push(x.name)
    })
    this.columnSelected = this.dictionaries[0].name
    this.rowSelected = this.dictionaries[0].name
    if (this.route) {
      this.routeName = this.route
    }
  },
  data () {
    return {
      columnSelected: null,
      columnOptions: [{ value: null, text: 'select columns' }],
      reduce: 'binary',
      reduceOptions: [
        { value: 'none', text: 'none' },
        { value: 'binary', text: 'binary' },
        { value: 'count', text: 'count' }
      ],
      routeName: 'Control',
      rowSelected: null,
      rowOptions: [{ value: null, text: 'select rows' }]
    }
  }
}
</script>

<style scoped lang="scss">
.table-matrix td {
  border: 1px solid rgb(175, 174, 174);
  font-size: 0.9em;
  padding: 1px;
}
</style>
