<template>
<div class="my-4">
  <b-container fluid>
    <b-row class="text-center mb-4" align-h="center" v-if="!permissions.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permissions.read">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loading && permissions.read">
      <b-col>
        <b-card class="mb-3">
          <b-form-group horizontal class="mt-4">
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Type to Search" />
                <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
            </b-input-group>
          </b-form-group>
            <b-form inline class="mb-2">
              <b-form-select class="mr-2" v-model="projectSelected" :options="projectOptionsDynamic"></b-form-select>
              <b-form-select class="mr-2" v-model="statusSelected" :options="statusOptionsDynamic"></b-form-select>
            </b-form>
          <b-form inline>
            <span class="mr-2"><strong>Showing {{rowsShow.length}} out of {{rows.length}} FAQs</strong></span>
            <b-button class="mr-2" variant="outline-primary" @click="resetFilters">reset filters</b-button>
            <b-button class="mr-2" v-if="permissions.add" variant="outline-primary" :to="{ name: 'FormNew', params: {model: 'faq' }}">add FAQ</b-button>
          </b-form>
        </b-card>
        <div>
          <b-card>
            <div class="mb-3" v-for="row in rowsShow" :key="row.id">
              <faq-simple
                :item="row"
                :username="user.username"
              />
            </div>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import workflow from '@/workflows/faq'

import FaqSimple from '@/components/FaqSimple.vue'

export default {
  name: 'FAQs',
  components: {
    FaqSimple
  },
  computed: {
    rowsShow: function () {
      let rows = []
      rows = this.rows
      if (this.projectSelected) {
        rows = _.filter(rows, row => row.faqproject.id === parseInt(this.projectSelected))
      }
      if (this.statusSelected) {
        rows = _.filter(rows, row => row.status_id === parseInt(this.statusSelected))
      }
      if (this.filter.length > 0) {
        rows = rows.filter(x => {
          const term = x.id + ' ' + x.question + ' ' + x.answer + ' ' + x.regulations + ' ' + x.insights + ' ' + x.opportunity
          return term.toUpperCase().match(this.filter.toUpperCase())
        })
      }
      return rows
    },
    projectOptionsDynamic: function () {
      let rows = []
      if (Array.isArray(this.rowsShow)) {
        rows = this.rowsShow
      }
      let result = []
      rows.forEach(x => {
        const row = { value: x.faqproject.id, text: x.faqproject.name }
        result.push(row)
      })
      result = _.uniqBy(result, 'value')
      result = _.sortBy(result, 'text')
      result = _.concat([{ value: null, text: 'all libraries' }], result)
      return result
    },
    statusOptionsDynamic: function () {
      let rows = []
      if (Array.isArray(this.rowsShow)) {
        rows = this.rowsShow
      }
      let result = []
      rows.forEach(x => {
        const row = { value: x.status_id, text: workflow.find(y => y.id === x.status_id).name }
        result.push(row)
      })
      result = _.uniqBy(result, 'value')
      result = _.sortBy(result, 'text')
      result = _.concat([{ value: null, text: 'all statuses' }], result)
      return result
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "KPMG Regulatory Horizon: FAQs"
    this.$store.commit('setBrand', 'FAQs')
    this.$stat.log({ page: 'faqs', action: 'open faqs' })
    this.permissions.read = ac.can(this.user.acgroups).readAny('faq').granted
    this.permissions.add = ac.can(this.user.acgroups).createAny('faq').granted
    if (this.permissions.read) {
      this.load()
    }
  },
  data () {
    const data = {
      permissions: {
        add: false,
        read: false
      },
      filter: '',
      loading: true,
      projectSelected: null,
      rows: [],
      statusSelected: null
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        this.rows = await this.$Amplify.API.get('cosmos', `/faqs/own/${this.user.username}`)
        this.loading = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    resetFilters: function () {
      this.projectSelected = null
      this.statusSelected = null
    }
  }
}
</script>

<style>
</style>
